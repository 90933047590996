import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import SanityLink from '~components/SanityLink'
import { useState } from 'react'
import { useSiteState } from '~context/siteContext'
import Video from '~components/Video'
import useBreakpoint from '~utils/useBreakpoint'
import { useEffect } from 'react'

const ProjectTile = ({ className, project }) => {

	const [dragging, setDragging] = useState(false)
	const [init, setInit] = useState()
	const [siteState] = useSiteState()
	const { isMobile } = useBreakpoint()
	const [imageIndex, setImageIndex] = useState(0)
	const [hover, setHover] = useState(false)

	return (
		<Wrap className={className} portrait={siteState.view === 'index'}>
			<div 
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<MediaWrap>
					{ siteState.view === 'gallery' ? 
						<>
							{ project.thumbnail.mediaType === 'image' &&
								<ThumbnailHover >
									<ProjectLink dragging={dragging} link={{document: project, linkType: 'internal'}}>
										<HoverPanels>
											{project?.thumbnail?.landscapeThumbnailImages?.map((image, i) => (
												<HoverPanel 
													key={image._key} 
													onMouseEnter={() => setImageIndex(i)}
												/>
											))}
										</HoverPanels>
										{project?.thumbnail?.landscapeThumbnailImages?.map((image, i) => (
											<LandscapeImage key={image._key} image={image} aspectRatio={1.5} show={i === imageIndex}/>
										))}
									</ProjectLink>
								</ThumbnailHover>
							}
							{ project.thumbnail.mediaType === 'video' &&
								<ProjectLink link={{document: project, linkType: 'internal'}}>
									<VideoContainer portrait={siteState.view === 'index'}>
										<StyledVideo src={project.thumbnail?.video?.video?.asset?.url}/>
									</VideoContainer>
								</ProjectLink>
							}
						</>
						:
						<>
							<ProjectLink link={{document: project, linkType: 'internal'}}>
								<PortraitImage image={project?.thumbnail?.portraitThumbnailImage} aspectRatio={0.75}/>
							</ProjectLink>
							<Backdrop 
								link={{document: project, linkType: 'internal'}}
								show={hover}
								portrait={siteState.view === 'index'} 
								color={project?.thumbnail?.imageBackground}
								relative={!project?.thumbnail?.portraitThumbnailImage}
							/>
						</>
					}
				</MediaWrap>
				<ProjectLink link={{document: project, linkType: 'internal'}}>
					<Details 
						// initial={(siteState.view === 'index' || isMobile) ? 'open' : 'collapsed'}
						// animate={(siteState.view === 'index' || isMobile) ? 'open' : 'collapsed'}
						// exit={{ opacity: 0, height: '0' }}
						// variants={{
						// 	open:	{ 
						// 		opacity: 1,
						// 		height: 'auto',
						// 		margin: '0.7em 0.7em 0.7em 0'
						// 	},
						// 	collapsed: { 
						// 		opacity: 0, 
						// 		height: 0,
						// 		margin: '0em 0em 0em 0em'
						// 	}
						// }}
					>
						<Title>
							{project.title}
							<GalleryViewDate show={siteState.view === 'gallery'}>{project.date}</GalleryViewDate>
						</Title>
						<IndexViewDate show={siteState.view === 'index'}>
							{project.date}
						</IndexViewDate>
					</Details>
				</ProjectLink>
			</div>
		</Wrap>
	)
}

const Wrap = styled.div`
	grid-column: ${props => props.portrait ? 'span 2' : 'span 4'};
	position: relative;
	${mobile}{
		grid-column: ${props => props.portrait ? 'span 4' : 'span 8'};
	}
`
const MediaWrap = styled.div`
	position: relative;
`
const ThumbnailHover = styled.div`
	position: relative;
	height: 0;
	padding-bottom: 66.67%;
	overflow: hidden;
`
const ProjectLink = styled(SanityLink)`
	width: 100%;
	pointer-events: ${props => props.dragging ? 'none' : 'all'};
	display: block;
`
const LandscapeImage = styled(Image)`
	position: absolute;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	opacity: ${props => props.show ? '1' : '0'};
`
const HoverPanels = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1;
`
const HoverPanel = styled.div`
	flex: 1;
`
const PortraitImage = styled(Image)`
	position: relative;
	z-index: 1;
`
const Backdrop = styled(SanityLink)`
	position: ${props => props.relative ? 'relative' : 'absolute'};
	padding-bottom: ${props => props.portrait ? '133.6%' : '67.1%'};
	height: 0;
	background-color: ${props => `${props.color}`};
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 0;
	opacity: ${props => props.show ? '1' : '0'};
	display: block;
	transition: opacity 0.3s;
	${mobile}{
		padding-bottom: 133.6%;
		opacity: 0;
	}
`
const VideoContainer = styled.div`
	padding-bottom: ${props => props.portrait ? '133.6%' : '67.1%'};
	height: 0;
	position: relative;
	${mobile}{
		padding-bottom: ${props => props.portrait ? '130.1%' : '67.1%'};
	}
`	
const StyledVideo = styled(Video)`
	margin: 0 auto;
	width: 100%;
	height: 100%;
	object-fit: cover;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;
`
const Details = styled.div`
	font-size: 13px;
	margin: 0.7em 0em 0em 0;
`
const Title = styled.div`
	margin-bottom: 0.02em;
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
`
const GalleryViewDate = styled.div`
	opacity: ${props => props.show ? '1' : '0'};
	transition: opacity 0.3s;
	${mobile}{
		display: none;
	}
`
const IndexViewDate = styled.div`
	display: ${props => props.show ? 'block' : 'none'};
	${mobile}{
		display: block;
	}
`

ProjectTile.propTypes = {
	className: PropTypes.string,
	project: PropTypes.object,
}

export default ProjectTile