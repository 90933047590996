import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import { Collapse } from 'react-collapse'
import {useSiteState} from '~context/siteContext'
import useScrolledTop from '~utils/useScrolledTop'
import { Dash } from '~components/Svg'

const FilterMobile = ({ className, onSortChange, onColourChange, colourNodes, initialColours, categoryNodes, initialCategories, onCategoryChange }) => {

	const [sort, setSort] = useState('latest')
	const [colours, setColours] = useState([])
	const [colourSelected, setColourSelected] = useState()
	const [categories, setCategories] = useState([])
	const [categorySelected, setCategorySelected] = useState()
	const [filter, setFilter] = useState(false)
	const top = useScrolledTop()
	const [siteState] = useSiteState()
	const [initialised, setInitialised] = useState(false)

	useEffect(() => {
		setColours(initialColours)
		setCategories(initialCategories)
	}, [])


	useEffect(() => {
		if (onSortChange){
			onSortChange(sort)
		}
	}, [sort])
	
	useEffect(() => {
		if (onColourChange && initialised){
			onColourChange(colours)
		}
		setInitialised(true)
	}, [colours.length])

	useEffect(() => {
		if (onCategoryChange && initialised){
			onCategoryChange(categories)
		}
		setInitialised(true)
	}, [categories.length])

	useEffect(() => {
		if(colours.length > 0){
			setColourSelected(true)
		} else {
			setColourSelected(false)
		}
	}, [colours])

	useEffect(() => {
		if(categories.length > 0){
			setCategorySelected(true)
		} else {
			setCategorySelected(false)
		}
	}, [categories])

	const toggleColour = (colour) => {
		let array = [...colours]
		let index = array.indexOf(colour.slug?.current)
		if(index !== -1){
			array.splice(index, 1)
			setColours(() => (array))
		} else {
			setColours(prevState => ([
				...prevState, colour?.slug?.current
			]))
		}
	}

	const toggleCategory = category => {
		let array = [...categories]
		let index = array.indexOf(category.slug?.current)
		if(index !== -1){
			array.splice(index, 1)
			setCategories(() => (array))
		} else {
			setCategories(prevState => ([
				...prevState, category?.slug?.current
			]))
		}
	}

	const toggleFilter = (value) => {
		if(filter === value){
			setFilter(undefined)
		} else {
			setFilter(value)
		}
	}

	return (
		<Wrap className={className}>
			<FlexOverlay headerHeight={siteState.headerHeight}/>
			<Relative>
				<Section>
					<Border />
					<FilterToggle 
						className='h4'
						onClick={()=> toggleFilter('category')}
					>
						<FilterButtonText>	
							Type
							<ActiveMarker show={categories.length > 0}/>
						</FilterButtonText>
					</FilterToggle>
					<FilterButton className='h4' onClick={() => setSort('latest')}>
						<FilterButtonText>
							Latest 
							<ActiveMarker show={sort === 'latest'}/>
						</FilterButtonText>  
					</FilterButton>
					<FilterButton className='h4' onClick={() => setSort('alphabetical')}>
						<FilterButtonText>
							Alphabetical
							<ActiveMarker show={sort === 'alphabetical'}/>
						</FilterButtonText> 
					</FilterButton>
					<FilterToggleRight 
						className='h4' 
						onClick={()=> toggleFilter('colour')}
					>
						<FilterButtonText>
							COLOUR
							<ActiveMarker show={colours.length > 0}/>
						</FilterButtonText>
					</FilterToggleRight>
				</Section>
				<DropDown>
					<Collapse isOpened={filter}>
						<Section>
							<Container>
								{filter === 'category' &&
									<>
										<DropDownButton 
											className='nav'
											onClick={() => setCategories([])} 
											notSelected={categorySelected}
										>
											All
										</DropDownButton>
										{categoryNodes?.nodes?.map(category => (
											<DropDownButton 
												key={category.slug?.current} 
												onClick={() => toggleCategory(category)}
												className='nav'
												notSelected={categorySelected && !categories?.includes(category.slug?.current)}
											>
												{category.title}
											</DropDownButton>
										))}
									</>
								}
								{filter === 'colour' &&
									<>	
										<DropDownButton 
											className='nav' 
											onClick={() => setColours([])}
											alignRight
										>
											All
										</DropDownButton>
										{colourNodes?.nodes?.map(colour => (
											<DropDownButton 
												key={colour.slug?.current} 
												onClick={() => toggleColour(colour)}
												className='nav'
												notSelected={colourSelected && !colours?.includes(colour.slug?.current)}
												alignRight
											>
												{colour.title}
											</DropDownButton>
										))}
									</>
								}
							</Container>
						</Section>
					</Collapse>
				</DropDown>
				<Section><BorderBottom /></Section>
			</Relative>
		</Wrap>		
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 0;
	z-index: 99;
	left: 0;
	right: 0;
	display: none;
	background-color: var(--white);
	${mobile}{
		display: block;
	}
`
const FlexOverlay = styled.div`
	height: ${props => props.headerHeight + 17}px;
	transition: height 0.25s;
`
const Relative = styled.div`
	position: relative;
	z-index: 99;
`
const Border = styled.div`
	border-top: 1px solid;
	grid-column: span 12;
	margin-bottom: 0.6vw;
`
const FilterButton = styled.button`
	grid-column: span 3;
	text-align: left;
	display: flex;
	align-items: center;
`
const FilterButtonText = styled.div`
	display: inline-block;
	position: relative;
`
const FilterToggle = styled.button`
	grid-column: span 3;
	text-align: left;
`
const FilterToggleRight = styled(FilterToggle)`
	text-align: right;
`
const ActiveMarker = styled(Dash)`
	width: 0.55em;
	/* height: 0.45em; */
	position: absolute;
	right: -0.8em;
	top: 50%;
	transform: translateY(-25%);
	opacity: ${props => props.show ? '1' : '0'};
	transition: opacity 0.25s;
`
const DropDown = styled.div`
	position: relative;
`
const Container = styled.div`
	grid-column: span 12;
	margin-top: 9px;
	margin-bottom: 6px;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
`
const DropDownButton = styled.button`
	display: block;
	opacity: ${props => props.notSelected ? '0.3' : '1'};
	transition: opacity 0.3s;
	text-align: ${props => props.alignRight ? 'right' : 'left'};
`
const BorderBottom = styled.div`
	border-top: 1px solid;
	grid-column: span 12;
	margin-top: 0.6vw;
`

FilterMobile.propTypes = {
	className: PropTypes.string,
	colourNodes: PropTypes.object,
	onSortChange: PropTypes.func,
	onColourChange: PropTypes.func,
	headerHeight: PropTypes.number,
	initialColours: PropTypes.array,
	categoryNodes: PropTypes.object,
	initialCategories: PropTypes.array,
}

export default FilterMobile